li{
    list-style: none;
    /* line-height: 28px; */
}
.nestedullistss{
    padding-left: 25px;
    background-color: #2c3b41;
    padding-top: 2px;
    padding-bottom: 5px;
}
.fa-circle-o{
    margin-right: 6px;
}
.nestedullistss li a,.nestedullistss li{
    font-size: 14px;
    color: rgb(101, 101, 101) ;
    margin-top: 9px;
}
.nestedullistss li a:hover{
    color: #fff ;
}