:root{
    --color-1 : #f79860;
    --color-2 : #5F2282;
    --white:#ffffff;
    --black:#000000;
    --error-color: #a76161;
    --text-color: #adb1c9;
}

.main-header{
    background: var(--color-2);
}
/* nav bar */
.skin-blue .main-header .navbar {
    background: transparent !important;
}
/* logo bg */
.skin-blue .main-header .logo {
    background: transparent !important;
    color:var(--white);
}

.skin-blue .main-header .navbar .sidebar-toggle:hover {
    background:var(--color-2) !important;
}

.skin-blue .sidebar-menu>li.active>a {
    border-left-color: var(--color-2);
}

.skin-blue .sidebar-menu .treeview-menu>li>a{
    color: var(--text-color);
}

.info-box{
     color: var(--white);
}

.skin-blue .main-header li.user-header {
    background: linear-gradient(180deg, var(--color-2), #7a675b) !important;
}

.form-error1 {
    color: var(--error-color);
}

.box.box-warning {
    border-top-color: var(--color-2);
    color: var(--white);
}
.btn-default:hover,
.btn-default:active,
.btn-default.hover {
    background-color: var(--color-2);
    color: #fff !important;
}
.nav-tabs-custom>.nav-tabs>li.active {
    border-top-color: var(--color-2);
}
.box.box-primary {
    border-top-color: var(--color-2);
}
.box.box-danger {
    border-top: none;
}

.box-header.with-border {
    background: var(--color-2);
    color: var(--white);
}

.main-footer {
    background-image: var(--color-2) !important;
    padding: 15px;
    color: var(--white);
}

.admin-action {
    list-style: none;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333 !important;
}

.btn-box-tool {
    padding: 5px;
    font-size: 12px;
    background: transparent;
    color: #ecf0f5;
}

div#track ul li {
    list-style-type: none;
    border-bottom: 2px solid #eee;
    padding: 8px;
    padding-left: 20px;
    color: #666;
    font-size: 14px;
    margin-top: 20px;
}

div#track ul li a {
    text-decoration: none;
    color: #666;
}

div#track ul li span {
    display: inline-block;
    float: right;
    padding-right: 10px;
}

.reposrtImage img {
    width: 50px;
    /* / top: 17px;/ position: absolute;
    / right: -68px;/ left: 0px; */
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    z-index: 3;
    color: var(--white);
    cursor: default;
    background-color: var(--color-2);
    border-color: var(--color-2);
}



i.fa.fa-edit.editIcon {
    font-size: 18px;
}

i.fa.fa-fw.fa-remove.deleteIcon {
    color: #b52929;
    font-size: 20px;
}



.skin-blue .wrapper,
.skin-blue .main-sidebar,
.skin-blue .left-side {
    background-color: #201804;
}

label{
    color: black;
}

.switch{
    margin-bottom: 0 !important;
}

.table>tbody>tr>td, 
.table>tbody>tr>th, 
.table>tfoot>tr>td, 
.table>tfoot>tr>th,
.table>thead>tr>td, 
.table>thead>tr>th{
    vertical-align: middle !important;
}